import './App.css';
import React, { useState, useEffect } from 'react';

function App() {
  const [classTestRun, setClassTestRun] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null); 

    try {
      const response = await fetch(`/backend/generate-pdf/${classTestRun}`);

      if (!response.ok) {
        throw new Error(`Error fetching PDF: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'report.pdf';
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An error occurred while generating the PDF.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      console.warn('Error message:', error);
    }
  }, [error]);

  return (
    <div className="container">
      <h1 className="header">Download the PDF</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter the class test run ID"
          value={classTestRun}
          onChange={(e) => setClassTestRun(e.target.value)}
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Generating PDF...' : 'Generate PDF'}
        </button>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
  
}

export default App;
